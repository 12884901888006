@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1732022817815/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1732022817815/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1732022817815/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-a_z:before { content: "\EA01" }
.icon-addthis:before { content: "\EA02" }
.icon-adult:before { content: "\EA03" }
.icon-arrow-bold:before { content: "\EA04" }
.icon-arrow-left:before { content: "\EA05" }
.icon-arrow-long:before { content: "\EA06" }
.icon-arrow-right:before { content: "\EA07" }
.icon-arrow-short:before { content: "\EA08" }
.icon-arrow:before { content: "\EA09" }
.icon-attention:before { content: "\EA0A" }
.icon-bed:before { content: "\EA0B" }
.icon-brand-shop:before { content: "\EA0C" }
.icon-buckelpiste:before { content: "\EA0D" }
.icon-cal:before { content: "\EA0E" }
.icon-car:before { content: "\EA0F" }
.icon-carving:before { content: "\EA10" }
.icon-chairlift:before { content: "\EA11" }
.icon-child:before { content: "\EA12" }
.icon-clock:before { content: "\EA13" }
.icon-close:before { content: "\EA14" }
.icon-closed:before { content: "\EA15" }
.icon-cloudy:before { content: "\EA16" }
.icon-contest:before { content: "\EA17" }
.icon-correct:before { content: "\EA18" }
.icon-crop:before { content: "\EA19" }
.icon-cut:before { content: "\EA1A" }
.icon-difficulty:before { content: "\EA1B" }
.icon-discount:before { content: "\EA1C" }
.icon-downloads:before { content: "\EA1D" }
.icon-earth:before { content: "\EA1E" }
.icon-elements:before { content: "\EA1F" }
.icon-email:before { content: "\EA20" }
.icon-error:before { content: "\EA21" }
.icon-facebook-shariff:before { content: "\EA22" }
.icon-facebook:before { content: "\EA23" }
.icon-facilities:before { content: "\EA24" }
.icon-flickr:before { content: "\EA25" }
.icon-flutlicht:before { content: "\EA26" }
.icon-fog:before { content: "\EA27" }
.icon-food:before { content: "\EA28" }
.icon-freeride:before { content: "\EA29" }
.icon-friendscard:before { content: "\EA2A" }
.icon-funpark:before { content: "\EA2B" }
.icon-gondola-2:before { content: "\EA2C" }
.icon-gondola-single:before { content: "\EA2D" }
.icon-gondola:before { content: "\EA2E" }
.icon-heart:before { content: "\EA2F" }
.icon-hiking:before { content: "\EA30" }
.icon-in-prep:before { content: "\EA31" }
.icon-info:before { content: "\EA32" }
.icon-instagram:before { content: "\EA33" }
.icon-kids:before { content: "\EA34" }
.icon-length:before { content: "\EA35" }
.icon-lift-gondel:before { content: "\EA36" }
.icon-lift-gub:before { content: "\EA37" }
.icon-lift-shuttle:before { content: "\EA38" }
.icon-lift-zauberteppich:before { content: "\EA39" }
.icon-linkedin:before { content: "\EA3A" }
.icon-loipe:before { content: "\EA3B" }
.icon-mail:before { content: "\EA3C" }
.icon-marker:before { content: "\EA3D" }
.icon-minus:before { content: "\EA3E" }
.icon-mountains:before { content: "\EA3F" }
.icon-move:before { content: "\EA40" }
.icon-nightlife:before { content: "\EA41" }
.icon-open:before { content: "\EA42" }
.icon-opened:before { content: "\EA43" }
.icon-order-history:before { content: "\EA44" }
.icon-pano-live:before { content: "\EA45" }
.icon-panorama:before { content: "\EA46" }
.icon-phone:before { content: "\EA47" }
.icon-pinterest:before { content: "\EA48" }
.icon-plane:before { content: "\EA49" }
.icon-play:before { content: "\EA4A" }
.icon-plus:before { content: "\EA4B" }
.icon-preparation:before { content: "\EA4C" }
.icon-profile-new:before { content: "\EA4D" }
.icon-profile:before { content: "\EA4E" }
.icon-race:before { content: "\EA4F" }
.icon-rain:before { content: "\EA50" }
.icon-rainlight:before { content: "\EA51" }
.icon-rodel:before { content: "\EA52" }
.icon-rotate-right:before { content: "\EA53" }
.icon-rotate:before { content: "\EA54" }
.icon-safety-first:before { content: "\EA55" }
.icon-search:before { content: "\EA56" }
.icon-search2:before { content: "\EA57" }
.icon-shopping-voucher:before { content: "\EA58" }
.icon-shopping:before { content: "\EA59" }
.icon-sightseeing:before { content: "\EA5A" }
.icon-ski-fast:before { content: "\EA5B" }
.icon-skibus:before { content: "\EA5C" }
.icon-skier:before { content: "\EA5D" }
.icon-skiroute:before { content: "\EA5E" }
.icon-skiservice:before { content: "\EA5F" }
.icon-skitest:before { content: "\EA60" }
.icon-skiticketing:before { content: "\EA61" }
.icon-sleet:before { content: "\EA62" }
.icon-slope-tour:before { content: "\EA63" }
.icon-slow-lift:before { content: "\EA64" }
.icon-snow:before { content: "\EA65" }
.icon-snow2:before { content: "\EA66" }
.icon-snow_cannon:before { content: "\EA67" }
.icon-snowboard:before { content: "\EA68" }
.icon-snowlight:before { content: "\EA69" }
.icon-social:before { content: "\EA6A" }
.icon-specials:before { content: "\EA6B" }
.icon-sport:before { content: "\EA6C" }
.icon-standseilbahn:before { content: "\EA6D" }
.icon-suitecase:before { content: "\EA6E" }
.icon-summer:before { content: "\EA6F" }
.icon-sunny:before { content: "\EA70" }
.icon-sunnycloud:before { content: "\EA71" }
.icon-sunnyfog:before { content: "\EA72" }
.icon-sunnyrain:before { content: "\EA73" }
.icon-sunnysleet:before { content: "\EA74" }
.icon-sunnysnow:before { content: "\EA75" }
.icon-sunnythunder:before { content: "\EA76" }
.icon-sunnythundersnow:before { content: "\EA77" }
.icon-teenager:before { content: "\EA78" }
.icon-temperatur:before { content: "\EA79" }
.icon-temporary_closed:before { content: "\EA7A" }
.icon-thumb:before { content: "\EA7B" }
.icon-thunder:before { content: "\EA7C" }
.icon-thundersnow:before { content: "\EA7D" }
.icon-train:before { content: "\EA7E" }
.icon-twitter-shariff:before { content: "\EA7F" }
.icon-twitter:before { content: "\EA80" }
.icon-upload:before { content: "\EA81" }
.icon-video-camera:before { content: "\EA82" }
.icon-webcam:before { content: "\EA83" }
.icon-whatsapp:before { content: "\EA84" }
.icon-winter:before { content: "\EA85" }
.icon-world:before { content: "\EA86" }
.icon-young-adult:before { content: "\EA87" }
.icon-youtube:before { content: "\EA88" }
.icon-zoom:before { content: "\EA89" }


:root {
--icon-a_z: "\EA01";
    --icon-addthis: "\EA02";
    --icon-adult: "\EA03";
    --icon-arrow-bold: "\EA04";
    --icon-arrow-left: "\EA05";
    --icon-arrow-long: "\EA06";
    --icon-arrow-right: "\EA07";
    --icon-arrow-short: "\EA08";
    --icon-arrow: "\EA09";
    --icon-attention: "\EA0A";
    --icon-bed: "\EA0B";
    --icon-brand-shop: "\EA0C";
    --icon-buckelpiste: "\EA0D";
    --icon-cal: "\EA0E";
    --icon-car: "\EA0F";
    --icon-carving: "\EA10";
    --icon-chairlift: "\EA11";
    --icon-child: "\EA12";
    --icon-clock: "\EA13";
    --icon-close: "\EA14";
    --icon-closed: "\EA15";
    --icon-cloudy: "\EA16";
    --icon-contest: "\EA17";
    --icon-correct: "\EA18";
    --icon-crop: "\EA19";
    --icon-cut: "\EA1A";
    --icon-difficulty: "\EA1B";
    --icon-discount: "\EA1C";
    --icon-downloads: "\EA1D";
    --icon-earth: "\EA1E";
    --icon-elements: "\EA1F";
    --icon-email: "\EA20";
    --icon-error: "\EA21";
    --icon-facebook-shariff: "\EA22";
    --icon-facebook: "\EA23";
    --icon-facilities: "\EA24";
    --icon-flickr: "\EA25";
    --icon-flutlicht: "\EA26";
    --icon-fog: "\EA27";
    --icon-food: "\EA28";
    --icon-freeride: "\EA29";
    --icon-friendscard: "\EA2A";
    --icon-funpark: "\EA2B";
    --icon-gondola-2: "\EA2C";
    --icon-gondola-single: "\EA2D";
    --icon-gondola: "\EA2E";
    --icon-heart: "\EA2F";
    --icon-hiking: "\EA30";
    --icon-in-prep: "\EA31";
    --icon-info: "\EA32";
    --icon-instagram: "\EA33";
    --icon-kids: "\EA34";
    --icon-length: "\EA35";
    --icon-lift-gondel: "\EA36";
    --icon-lift-gub: "\EA37";
    --icon-lift-shuttle: "\EA38";
    --icon-lift-zauberteppich: "\EA39";
    --icon-linkedin: "\EA3A";
    --icon-loipe: "\EA3B";
    --icon-mail: "\EA3C";
    --icon-marker: "\EA3D";
    --icon-minus: "\EA3E";
    --icon-mountains: "\EA3F";
    --icon-move: "\EA40";
    --icon-nightlife: "\EA41";
    --icon-open: "\EA42";
    --icon-opened: "\EA43";
    --icon-order-history: "\EA44";
    --icon-pano-live: "\EA45";
    --icon-panorama: "\EA46";
    --icon-phone: "\EA47";
    --icon-pinterest: "\EA48";
    --icon-plane: "\EA49";
    --icon-play: "\EA4A";
    --icon-plus: "\EA4B";
    --icon-preparation: "\EA4C";
    --icon-profile-new: "\EA4D";
    --icon-profile: "\EA4E";
    --icon-race: "\EA4F";
    --icon-rain: "\EA50";
    --icon-rainlight: "\EA51";
    --icon-rodel: "\EA52";
    --icon-rotate-right: "\EA53";
    --icon-rotate: "\EA54";
    --icon-safety-first: "\EA55";
    --icon-search: "\EA56";
    --icon-search2: "\EA57";
    --icon-shopping-voucher: "\EA58";
    --icon-shopping: "\EA59";
    --icon-sightseeing: "\EA5A";
    --icon-ski-fast: "\EA5B";
    --icon-skibus: "\EA5C";
    --icon-skier: "\EA5D";
    --icon-skiroute: "\EA5E";
    --icon-skiservice: "\EA5F";
    --icon-skitest: "\EA60";
    --icon-skiticketing: "\EA61";
    --icon-sleet: "\EA62";
    --icon-slope-tour: "\EA63";
    --icon-slow-lift: "\EA64";
    --icon-snow: "\EA65";
    --icon-snow2: "\EA66";
    --icon-snow_cannon: "\EA67";
    --icon-snowboard: "\EA68";
    --icon-snowlight: "\EA69";
    --icon-social: "\EA6A";
    --icon-specials: "\EA6B";
    --icon-sport: "\EA6C";
    --icon-standseilbahn: "\EA6D";
    --icon-suitecase: "\EA6E";
    --icon-summer: "\EA6F";
    --icon-sunny: "\EA70";
    --icon-sunnycloud: "\EA71";
    --icon-sunnyfog: "\EA72";
    --icon-sunnyrain: "\EA73";
    --icon-sunnysleet: "\EA74";
    --icon-sunnysnow: "\EA75";
    --icon-sunnythunder: "\EA76";
    --icon-sunnythundersnow: "\EA77";
    --icon-teenager: "\EA78";
    --icon-temperatur: "\EA79";
    --icon-temporary_closed: "\EA7A";
    --icon-thumb: "\EA7B";
    --icon-thunder: "\EA7C";
    --icon-thundersnow: "\EA7D";
    --icon-train: "\EA7E";
    --icon-twitter-shariff: "\EA7F";
    --icon-twitter: "\EA80";
    --icon-upload: "\EA81";
    --icon-video-camera: "\EA82";
    --icon-webcam: "\EA83";
    --icon-whatsapp: "\EA84";
    --icon-winter: "\EA85";
    --icon-world: "\EA86";
    --icon-young-adult: "\EA87";
    --icon-youtube: "\EA88";
    --icon-zoom: "\EA89";
    
}