.main-nav {
    position: absolute;
    z-index: 99;
    width:100%;
    color: #6b6b6b;
    padding: 0;
    background-color: #EAEAEA;
    top: 0;
    left: 0;
    right: 0;
}
.main-nav.nav-fixed {
    position: fixed;
}
.main-nav.has-padding {
    @media screen and (min-width: 1200px) {
        padding: calc(17rem/16) 0;
    }
}
.main-nav .navbar-collapse {
    justify-content: flex-end;
}
.main-nav__brand-img {
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: calc(150rem/16);
    }
}
.main-nav__nav-wrapper {
    max-width: 25%;
    width:100%;
    display:flex;
    align-items:center;
}
.main-nav__content-wrapper {
    width: 100%;
}
.main-nav__nav {
    height:100%;
}

.main-nav__nav-link {
    font-size: calc(16rem/16);
    line-height: calc(19/16);
}
.main-nav__link-back {
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
    margin-left: calc(25rem/16);
}

.main-nav__nav-item--only-icon .main-nav__nav-link {
    font-size: calc(25rem/16);
}

.main-nav__nav-wrapper--center {
    width: 100%;
    display: flex;
    align-items: center;
}
.main-nav__nav-wrapper--right {
    max-width: inherit;
    justify-content: flex-end;
}
.main-nav__nav-wrapper--center .main-nav__nav-link {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}
.main-nav__nav-wrapper--right .main-nav__nav-item {
    margin-right: calc(10rem/16);
}
.main-nav__nav-wrapper--right .main-nav__nav-item .main-nav__nav-link {
    padding: calc(8rem/16) calc(5rem/16);
}

/* cart button */
.main-nav .main-nav__nav-item--cart {
    text-transform: uppercase;
    height: 100%;
    margin-right: 0;
    background-color: var(--color-secondary);
    position:relative;
    @media screen and (min-width: 1200px) {
        margin-left: calc(20rem/16);
    }
}
.main-nav__nav-item--cart .main-nav__nav-item-btn {
    color: var(--color-white);
    font-size: calc(24rem/16);
    padding: calc(30rem/16);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: calc(20rem/16);
        padding: calc(20rem/16) calc(15rem/16);
    }
}
.main-nav__nav-item--cart-badge {
    position:absolute;
    left:0;
    font-size: calc(16rem/16);
    justify-content:center;
    align-items:center;
    display:flex;
    bottom:0;
    z-index: 1;
    width: calc(30rem/16);
    height: calc(30rem/16);

}
.main-nav__nav-item--cart-badge:before {
    content:'';
    position:absolute;
    display:block;
    z-index: -1;
    left:0;
    bottom:0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: calc(50rem/16) 0 0 calc(50rem/16);
    border-color: transparent transparent transparent var(--color-secondary-dark);
}

/* toggler mobile */
@media screen and (max-width: 767px) {
    .main-nav__toggler {
        position: relative;
        background-color: transparent;
        width: calc(50rem/16);
        height: calc(50rem/16);
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: calc(10rem/16);
        transition: .5s ease-in-out;
    }
    .navbar-toggler.main-nav__toggler:focus {
        outline: none;
    }
    .main-nav__toggler-bar {
        display: block;
        height: calc(3rem/16);
        width: calc(31rem/16);
        background-color: var(--color-text-default);
        padding: 0;
        position: absolute;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        transform-origin: left center;
    }
    .main-nav__toggler .main-nav__toggler-bar:first-child{
        top: calc(12rem/16);
    }
    .main-nav__toggler .main-nav__toggler-bar:nth-child(2) {
        top: calc(20rem/16);
    }
    .main-nav__toggler .main-nav__toggler-bar:last-child{
        top: calc(28rem/16);
    }
    .main-nav__toggler.is-open .main-nav__toggler-bar:first-child{
        transform: rotate(45deg);
        top: calc(8rem/16);
        left: calc(8rem/16);
    }
    .main-nav__toggler.is-open .main-nav__toggler-bar:nth-child(2) {
        width: 0;
        opacity: 0;
    }
    .main-nav__toggler.is-open .main-nav__toggler-bar:last-child{
        transform: rotate(-45deg);
        top: calc(30rem/16);
        left: calc(8rem/16);
    }
}

/* mobile nav */
@media screen and (max-width: 767px) {
    .main-nav__content-wrapper {
        position: fixed;
        background-color: #EAEAEA;
        text-align: center;
        height: 100%;
        top: calc(54rem / 16);
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 200;
        overflow-y: auto;
        overflow-x: hidden;
        transform: translateX(100%);
        transition: transform 180ms ease-out;
        will-change: transform;
    }
    .main-nav__nav-wrapper--center {
        padding: calc(50rem/16) calc(10rem/16);
    }
    .main-nav__nav-wrapper--right {
        justify-content: center;
        max-width: inherit;
    }
    .main-nav__nav-item {
        padding: calc(5rem/16) 0;
    }
    .main-nav__nav-link {
        font-size: calc(20rem/16);
    }

    .main-nav__content-wrapper.is-open {
        transform: translateX(0);
    }

    .main-nav .navbar-collapse {
        justify-content: center;
    }
}