html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}

.page-wrapper {
    padding-top: calc(88rem/16);

    @media screen and (max-width: 1199px) {
        padding-top: calc(54rem/16);
        padding-bottom: calc(30rem/16);
    }
}
.page-wrapper--full-height {
    min-height: calc(100vh - 250px);

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        min-height: calc(100vh - 191px);
    }
}

html.is-scrolling-disabled,
html.is-scrolling-disabled body {
    overflow: hidden;


}
html.is-scrolling-disabled body {
    position: relative;

    @media screen and (max-width: 767px) {
        position: fixed;
        left: 0;
        right: 0;
    }
}