.list-member-promocode {

}
.list-member-promocode__header {
    border-bottom: 2px solid var(--color-grey);
    padding-bottom: calc(10rem/16);
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.list-member-promocode__body {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding-top: calc(10rem/16);
}
.list-member-promocode__row {
    display: flex;
    align-items: center;
    position: relative;
    column-gap: calc(16rem/16);
    &:after {
        content: "";
        position: absolute;
        bottom: calc(-10rem/16);
        left: 0;
        right: 0;
        height: 1px;
        background-color: var(--color-grey);
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: calc(8rem/16);
    }
}
.list-member-promocode__cell {
    flex: 1;
    @media screen and (min-width: 768px) {
        min-width: 10%;
        overflow-wrap: break-word;
    }
}
.list-member-promocode__cell--narrow {
    @media screen and (min-width: 768px) {
        min-width: 7%;
    }
}
.list-member-promocode__cell--wide {
    @media screen and (min-width: 768px) {
        min-width: 20%;
    }
}