.checkout-item__header {
    padding: calc(25rem/16) calc(28rem/16);
    background-color: #eaeaea;
    color: #6b6b6b;
    font-size: calc(16rem/16);
    border: 1px solid #C8C8C8;

    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.checkout-item__body {
    padding: calc(25rem/16) calc(60rem/16);
    background-color: transparent;
    color: #6b6b6b;
    font-size: calc(16rem/16);
    border: 1px solid #C8C8C8;

    @media screen and (max-width: 767px) {
        padding: calc(25rem/16);
    }
}
.checkout-item__price {
    @media screen and (min-width: 1200px) {
        text-align: right;
    }
}