.discount-badge {
    padding: calc(8rem/16);
    background-color: var(--color-success);
    z-index: 1;
    height: calc(46rem/16);
    display:flex;
    justify-content:center;
    align-items:center;
}
.discount-badge:before {
    content: '';
    z-index: 2;
    position: absolute;
    top: 42%;
    right: 100%;
    width: calc(7rem/16);
    height: calc(7rem/16);
    opacity: .95;
    background: #ffffff;
    border-radius: calc(7rem/16);
    box-shadow: inset .5px 0 rgba(0, 0, 0, 0.6);
}
.discount-badge:after {
    right: 100%;
    border: calc(23rem/16) solid var(--color-success);
    border-color: transparent var(--color-success) transparent transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    top: 50%;
    margin-top: calc(-23rem/16);
}
.discount-badge span {
    color: #fff;
    font-size: calc(18rem/16);
    text-align: center;
    font-family: var(--font-default-bold);
}