.ticket-item {
    margin-bottom: calc(30rem/16);
    @media screen and (max-width: 767px) {
        border-bottom: 1px solid var(--color-light-grey);
    }
}
.ticket-item__teaser {
    background-color: var(--color-lightest-grey);
    border:1px solid var(--color-light-grey);
    font-size: 1rem;
    font-family: var(--font-default-bold);
    position:relative;
}
.ticket-item__teaser-inner {
    padding: calc(10rem/16) calc(15rem/16);
}
.ticket-item__teaser .is-loading:before {
    z-index: 3;
    content:'';
    position:absolute;
    width:100%;
    height:100%;
    display:block;
    background-color:#fff;
    opacity:0.8;
}