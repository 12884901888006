.ticket-list__footer {
    background-color:#fff;
    border-top:2px solid var(--color-dark);
    z-index: 10;
    padding: 0;
    @media screen and (min-width: 767px) {
        height: calc(80rem/16);
    }
}
.ticket-list__footer-title {
    font-size: calc(20rem/16);
    text-transform: uppercase;
}
.ticket-list__footer-btn {
    border-radius:0;
    height:100%;
    display:inline-flex;
    justify-content: center;
    align-items:center;
    @media screen and (max-width: 767px) {
        display:block;
    }
}