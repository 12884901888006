.form-errors {
    color: var(--color-danger);
}
.form-errors:empty {
    display: none;
}
.form-errors ul {
    display: block;
    list-style-type: none;
    padding: 0;
    margin: 0;
}
ul.parsley-errors-list {
    color: var(--color-danger);
    font-size: calc(14rem/16);
    list-style-type: none;
    position: absolute;
    right: calc(25rem/16);
}
.form-control.has-error,
.form-group.has-error .form-control {
    border-color: var(--color-danger);
}

.is-disabled .form-errors ul{
    display: none;
}
.is-disabled .form-control.has-error,
.is-disabled .form-group.has-error .form-control {
    border-color: var(--color-primary);
}
