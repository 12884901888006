.cart-item {
    background-color: var(--color-lightest-grey);
    border:1px solid var(--color-light-grey);
    font-size: 1rem;
    font-family: var(--font-default-bold);
}
.cart-item__inner {
    padding: calc(10rem/16) calc(15rem/16);
}
.cart-item__price-discount {
    display:block;
    font-size: calc(12rem/16);
    line-height: 1;
    text-decoration: line-through;
}